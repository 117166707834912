import React from "react";
import experience from "../images/executive-coach-dublin-experince.svg";
import comms from "../images/executive-coach-angela-comm.svg";
import qualified from "../images/angela-cooney-qualified-coach.svg";

import { Link, graphql } from "gatsby";

import Layout from "../components/layout";

import StyledButton from "../components/StyledButton";
import { LatestBlogList } from "../components/BlogList";

import ServicesList from "../components/servicesList";

import SEO from "../components/seo";
import styled from "styled-components";

import BackgroundImage from "gatsby-background-image";


const BackgroundSection = ({ imageData, className, children }) => {
  return (
    <BackgroundImage Tag="div" className={className} fluid={imageData}>
      {children}
    </BackgroundImage>
  );
};

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: top;
`;

const Service = ({ title, url, children }) => {
  return (
    <div className="w-full p-3">
      <Link
        to={url}
        className="text-2xl text-teal-700 hover:text-indogo-600 hover:underline"
      >
        {title}
      </Link>
      <p>{children}</p>
    </div>
  );
};

function Index({ data }) {
  const blogPosts = data.blogPosts.nodes
  const bgImageFluid = data.bgImage.childImageSharp.fluid;

  return (
    <Layout className="relative bg-white" bodyClass="px-0 md:px-0 lg:px-0">
      <SEO title="Executive Leadership Coaching" />

      <div className="min-h-screen h-full flex flex-col">
        <StyledBackgroundSection imageData={bgImageFluid}>
          <div className="container mx-auto px-6 md:px-10 lg:px-16">
            <div className="w-4/5 md:w-3/4 lg:w-7/12 mt-20 md:mt-24 self-start p-4">
              <h1 className="text-4xl md:text-6xl text-pink-600 font-heading leading-normal">
                Angela Cooney
              </h1>
              <h2 className="text-4xl md:text-6xl text-teal-700 font-heading leading-normal">
                Executive Coach
              </h2>
              <p className="text-1xl md:text-2xl  text-white text-shadow-sm">
                <strong>
                  {" "}
                  Motivating Change to <br /> Maximise Potential
                </strong>
              </p>
              <p className="text-1xl p-2 flex flex-row flex-1 space-x-3">
                <Link to="/contact">
                  <StyledButton className="bg-pink-600 hover:bg-pink-500 p-2">
                    Get In Contact
                  </StyledButton>
                </Link>
                <Link to="/blog">
                  <StyledButton className="bg-pink-600 hover:bg-pink-500 p-2 ">
                    Checkout my blog
                  </StyledButton>
                </Link>
              </p>
            </div>
          </div>
        </StyledBackgroundSection>
        <div className="container mx-auto px-6 md:px-10 lg:px-16 bg-purple-900 heropattern-texture-teal-900">
          <div className="flex flex-col mt-10 mb-10">
            <div className="mb-2 text-4xl  self-center">
              <h3 className="font-heading text-white">
                My Coaching Philosophy
              </h3>
            </div>
            <p className=" sm:p-15 p-5 text-teal-400  md:text-3xl lg:text-3xl text-2xl leading-tight">
              <span className="text-4xl text-pink-600 ">"</span>
              <Link to="/coaching">
                Coaching helps us see things differently, from a fresh
                perspective, which leads to changes in our thinking and
                ultimately, in our behaviour{" "}
              </Link>
              <span className="text-4xl text-pink-600">"</span>
            </p>
          </div>
        </div>
        <div className="container mx-auto px-6 md:px-10 lg:px-16 mb-5 ">
          <div className="flex flex-col mt-5 mb-5">
            <div className="text-4xl  self-center">
              <h3 className="font-heading text-teal-900">Testimonials</h3>
            </div>
            <div className="flex flex-col bg-white rounded-xl shadow-inner mx-auto my-10  sm:w-9/12 md:w-10/12 lg::w-2/3 xl:w-9/12 w-full">
              <blockquote className="sm:m-4 md:m-8 lg:m-8 xl:m-10 m-4  bg-white border-teal-600 border-l-2">
                <p className="font-semibold m-3 text-shadow-xl ">
                  {" "}
                  "For me, each session was a valuable investment in my own
                  future."{" "}
                </p>
              </blockquote>
              <div className="text-white bg-pink-600 p-8 rounded-b-xl bg-whitemd:flex md:items-center">
                <div>
                  <p className="font-bold"> Natalia Geller </p>
                  <p>
                    Director EMEA{" "}
                    <span className="text-teal-900 font-bold">|</span> Tech
                    Sector
                  </p>
                </div>
              </div>
            </div>
            <div className="self-center mt-8">
              <Link to="/about">
                <StyledButton className="bg-teal-900 hover:bg-teal-500">
                  More About Me
                </StyledButton>
              </Link>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-6 md:px-10 lg:px-16 bg-teal-700">
          <div className="flex flex-col mt-16 mb-20">
            <div className="text-4xl  self-center">
              <h3 className="font-heading text-purple-900">My Services</h3>
            </div>
            <ServicesList />
            <div className="self-center mt-8">
              <Link to="/services">
                <StyledButton className="bg-pink-600 hover:bg-pink-500">
                  Find out more
                </StyledButton>
              </Link>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-6 md:px-10 lg:px-16 ">
          <div className="flex flex-col mt-10 mb-10">
            <div className="mb-2 text-4xl  self-center">
              <h3 className="mb-2 text-4xl text-purple-900 self-center font-heading">
                My Offering
              </h3>
            </div>

            <div className="flex flex-wrap justify-center items-stretch -mx-2 ">
              <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
                <div className="h-full m-2 p-4 border-2 border-purple-900  rounded flex flex-col items-center text-center">
                  <div className="w-16 h-16 mb-4 ">
                    <img
                      alt="Angela Cooney - Experienced Executive Coach"
                      src={experience}
                    />
                  </div>
                  <p className="text-2xl text-purple-900  w-full">
                    20 years experience
                  </p>
                  <p className="text-teal-900">
                    I have worked in global business roles across many
                    industries
                  </p>
                </div>
              </div>

              <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
                <div className="h-full m-2 p-4 border-2 border-purple-900 rounded flex flex-col items-center text-center">
                  <div className="w-16 h-16 mb-4">
                    <img
                      alt="Free Executive Coaching Consultation"
                      src={comms}
                    />
                  </div>
                  <p className="text-2xl text-purple-900  w-full">
                    Free Consultation
                  </p>
                  <p className="text-teal-900">
                    New clients receive a free consultation
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
                <div className="h-full m-2 p-4 border-2  border-purple-900 rounded flex flex-col items-center text-center">
                  <div className="w-16 h-16 mb-4">
                    <img
                      alt="EMCC Certified Executive Coaching "
                      src={qualified}
                    />
                  </div>
                  <p className="text-2xl text-purple-900  w-full">
                    Certified Coach
                  </p>
                  <p className="text-teal-900">
                    I am an accredited EMCC Senior Practitioner Coach
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <LatestBlogList blogPosts={blogPosts} />
        
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    bgImage: file(
      relativePath: { eq: "curves-of-modern-architechture-black-and-white.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogPosts: allWpPost(limit: 3 sort: { fields: [date], order: DESC}) {
      nodes {
        id
        title
        excerpt
        slug
        date(formatString: "DD MMMM YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AVIF, WEBP, JPG])
              }
            }
          }
        }
        categories {
        nodes {
          name
        }
      }
      } 
    }
  }
`;

export default Index;
